import React from 'react';

const superScriptFormat = (text) => {
  const subscripts = /[\u2070-\u209F\u00B2\u00B3\u00B9\u2120\u00AE\u2122]/;
  const result = (text.match(subscripts));
  const outcome = { text, result };
  if (outcome.result) {
    const part1 = outcome.text.slice(0, outcome.result?.index);
    const part2 = outcome.text.slice(outcome.result?.index, outcome.result?.index + 1);
    const part3 = outcome.text.slice(outcome.result?.index + 1, outcome.text.length - 1);
    const htmlString = `${part1}<sup id="superText">${part2}</sup>${part3}`;
    return <span dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }
  return text;
};

export default superScriptFormat;
